<template>
  <Breadcrumbs title="" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header" v-if="this.eventDetails.title">
            <div class="d-flex justify-content-center">
              <figure class="col-xl-10 col-sm-12">
                <img :src="this.eventDetails.coverurl"  style="box-shadow: 0em 0em 1em rgb(0 0 0 / 50%);border-bottom-left-radius: 0.6em;border-bottom-right-radius: 0.6em;border: 0px;" alt="Image description"
                  class="img-thumbnail" />
              </figure>
            </div>

            <div v-if="this.eventDetails.is_open_register === 'Y' && this.eventDetails.is_registration_start === 'Y'" >
              <a :href="this.$route.params.event_id + '/register'">
                <button class="btn btn-lg btn-primary" style="width: 100%;"><vue-feather type="edit" stroke-width="1"></vue-feather> สมัครเข้าร่วมกิจกรรม</button>
              </a>
            </div>
            <div  v-if="this.eventDetails.is_registration_start === 'Y' && this.eventDetails.is_open_register === 'N'" >
                <button disabled class="btn btn-lg btn-dark" style="width: 100%;"><vue-feather type="edit" stroke-width="1"></vue-feather> ยังไม่เปิดระบบรับสมัครขณะนี้</button>
            </div>
            <div  v-if="this.eventDetails.is_registration_start === 'N' && this.eventDetails.is_open_register === 'Y'" >
                <button disabled class="btn btn-lg btn-dark" style="width: 100%;"><vue-feather type="edit" stroke-width="1"></vue-feather> เปิดรับสมัครพร้อมกัน {{ this.eventDetails.registration_start_text }}</button>
            </div>
            
            <p>&nbsp;</p>
            <h3>{{ this.eventDetails.title }}</h3>
            <p>
              <b><i class="fa fa-calendar-check-o" aria-hidden="true"></i> วันที่จัดกิจกรรม: {{
            this.eventDetails.event_date_text }} </b>
              <br />
              <b>&nbsp;<i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;ณ: {{ this.eventDetails.location }}
              </b>
              <br />

            </p>
            <div style="border: 1px;padding: 10px;" v-html="this.eventDetails.content" class="card"></div>

        <div class="card">
              <div v-if="this.eventDetails.is_open_register === 'Y' && this.eventDetails.is_registration_start === 'Y'" >
              <a :href="this.$route.params.event_id + '/register'">
                <button class="btn btn-lg btn-primary" style="width: 100%;"><vue-feather type="edit" stroke-width="1"></vue-feather> สมัครเข้าร่วมกิจกรรม</button>
              </a>
            </div>
            </div>

          </div>

          <div class="card-header" v-if="!this.eventDetails.title">
            <div class="d-flex justify-content-center">
              <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
            </div>
            <div class="d-flex justify-content-center">
              กำลังโหลดข้อมูลกิจกรรม...
            </div>
          </div>
        </div>
        <div class="col-xl-12" v-if="this.eventDetails.title">
        <div class="card">

          <div class="card-header">
            <h4><vue-feather type="search"></vue-feather> ตรวจสอบรายชื่อ </h4>
            <form @submit.prevent="findOrder">
            <div class="row">
              <div class="col-sm-1"></div>
            <div class="col-xl-10 col-sm-10">
                        <label for="moblieNo">
                          <small class="form-text text-muted">ตรวจสอบรายชื่อ ด้วยหมายเลขโทรศัพท์</small>
                        </label>
                        <input
                          required
                          maxlength="10"
                          minlength="10"
                          type="text"
                          class="form-control"
                          id="moblieNo"
                          placeholder="หมายเลขโทรศัพท์"
                          v-model="txt_search"
                        />
            </div>
            <div class="col-sm-1"></div>                   
            </div>
            <div class="row">&nbsp;</div>
                <div class="row">
                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      style="cursor: pointer"
                      class="btn btn-primary btn-lg"
                    >
                    <vue-feather type="loader" size="20" animation="spin" v-if="this.is_searching ==='true'"></vue-feather> ค้นหา
                    </button>
                    &nbsp;
                    <button
                      style="cursor: pointer"
                      @click.prevent="clear_result"
                      type="reset"
                      class="btn btn-secondary btn-lg"
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
            </form>
          </div>
          <div class="card">
            <div class="row" v-if="this.is_start_find_order === 'Y'" >
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                  <div class="card-body">
                    <div v-if="this.is_find_order === 'Y'">
                      <div class="d-flex justify-content-center">
                        <img width="60" src="../../assets/images/running_loading.svg" />
                      </div>
                    </div>

                    <div  v-if="!this.result_find_order.length && this.is_find_order === 'N'">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">วันที่สั่งซื้อ</th>
                            <th scope="col">สถานะ</th>
                            <th scope="col">ดูคำสั่งซื้อ</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="3" style="text-align: center">
                              ไม่พบข้อมูลคำสั่งซื้อ
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div v-if="this.result_find_order.length">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">วันที่สมัคร</th>
                            <th scope="col">กิจกรรม</th>
                            <th scope="col">สถานะ</th>
                            <th scope="col">ใบสมัคร</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(src, index) in this.result_find_order" :key="index">
                            <td>{{ src.create_date }}</td>
                            <td>{{ src.title }}</td>
                            <td v-if="src.status === 'INITIAL'">
                              <font style="color: red;">{{this.currentLanguage ==='en'?"Waiting for payment":"รอชำระเงิน"}}</font>
                            </td>
                            <td v-if="src.status === 'PAID'">
                              <font style="color: green;">{{this.currentLanguage ==='en'?"Completed":"สำเร็จ"}}</font>
                            </td>
                            <td> <a target="_blank" :href=" '/order/' + src.order_id"><u><b>{{ src.order_id }}</b></u></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
      </div>

      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router";
import axios from "axios";
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'


export default {
  setup() {
    const siteData = reactive({
      title: `ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH`,
      description: `ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH`,
      keywords: `ระบบรับสมัครงานวิ่ง, งานวิ่ง,runrace,สนามวิ่ง,วิ่งใหนดี`,
      image: process.env.VUE_APP_IMAGE_COVER,
    })

    useHead({
      // Can be static or computed this.eventDetails.title
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords),
        },
        {
          property: `og:image`,
          content: computed(() => siteData.image),
        },
      ],

    })

    return { siteData};

  },

  data() {
    return {
      isFoundImage: "true",
      eventDetails: {},
      eventPackage: {},
      is_find_order: "N",
      is_start_find_order: "N",
      result_find_order: {},
      txt_search: "",
      s3_url: process.env.VUE_APP_AWS_S3_URL,
    };
  },
  async mounted() {
    await this.getEventDetail(this.$route.params.event_id);
    //this.siteData.image = _data.coverurl ;
    //this.siteData.title = _data.title +' '+this.siteData.title ;
    //this.siteData.description = _data.title +' วันที่ '+_data.event_date_text+' ณ.'+_data.province+' '+this.siteData.description ;

  },
  methods: {
    async findOrder() {

      if(this.txt_search === '' || this.txt_search === null){
        return
      }

      this.is_find_order = "Y";
      this.resultList = {};
      this.is_start_find_order = "Y"


      const data = {
        tk: process.env.VUE_APP_FN_TO_BK_SECRET_KEY,
        txt_search: this.txt_search,
        event_key: this.$route.params.event_id

      };
      let result = await axios.post("/v1/order/find-order", data);
      if (result.data.status === "success") {
        this.result_find_order = result.data.body;
      }else{
        this.is_find_order = "N";
      }
      this.is_find_order = "N";
      },
      async clear_result() {
        this.result_find_order = {};
        this.txt_search = "";
        this.is_find_order = "N"
        this.is_start_find_order = "N"
      }
    ,async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
      if (res.data.status === "success") {
        this.eventDetails = res.data;

        this.siteData.title = this.eventDetails.title +" "+ this.siteData.title
      } else {
        router.push({ path: "/404" });
      }
    }
  },
};
</script>

<style scoped>
.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #04AA6D;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}
</style>
