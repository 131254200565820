<template>
  <Breadcrumbs title="" main="Home" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h3>กิจกรรมที่เปิดรับสมัคร</h3>
          </div>
          <div class="my-gallery card-body row gallery-with-description" itemscope="">
            <div v-if="loading" class="d-flex justify-content-center">
              <img style="align-items: center; width: 50px" src="../../assets/images/running_loading.svg" />
            </div>
            <div class="my-gallery card-body row gallery-with-description" itemscope="" v-else>
              <figure class="col-xl-4 col-sm-6" v-for="(src, index) in this.events_open" :key="index">
                <a v-bind:href="src.event_key">
                  <img :src="src.coverurl" style="border-top-left-radius: 0.6em;border-top-right-radius: 0.6em;border: 0px;filter: drop-shadow(0 -1mm 4mm rgb(0, 0, 0,20%));" alt="Image description" class="img-thumbnail" />
                  <div class="caption" style="box-shadow: 0 0em 1em rgb(0 0 0 / 50%);border-bottom-left-radius: 0.6em;border-bottom-right-radius: 0.6em;border: 0px;">
                    <h4>{{ src.title }}</h4>
                    <b><i class="fa fa-calendar-check-o" aria-hidden="true"></i> {{ src.event_date_text }}</b>
                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> &nbsp;{{ src.location }}</p>
                  </div>
                </a>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="this.events_soon.length > 0">
      <div class="col-sm-12">
        <div class="card-header">
          <h3>กิจกรรมที่กำลังจะเปิดรับสมัคร</h3>
        </div>
        <div class="card">

          <div v-if="loading" class="d-flex justify-content-center">
            <img style="align-items: center; width: 50px" src="../../assets/images/running_loading.svg" />
          </div>
          <div v-else class="my-gallery card-body row gallery-with-description" itemscope="">
            <figure class="col-xl-4 col-sm-6" v-for="(src, index) in this.events_soon" :key="index">
              <a v-bind:href="src.event_key">
                <img :src="src.coverurl" style="border-top-left-radius: 0.6em;border-top-right-radius: 0.6em;border: 0px;filter: drop-shadow(0 -1mm 4mm rgb(0, 0, 0,20%));" alt="Image description" class="img-thumbnail" />
                <div class="caption" style="box-shadow: 0 0em 1em rgb(0 0 0 / 20%);border-bottom-left-radius: 0.6em;border-bottom-right-radius: 0.6em;border: 0px;">
                  <h4>{{ src.title }}</h4>
                  <b><i class="fa fa-calendar-check-o" aria-hidden="true"></i> {{ src.event_date_text }}</b>
                  <p><i class="fa fa-map-marker" aria-hidden="true"></i> {{ src.location }}</p>
                </div>
              </a>
            </figure>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="this.events_end.length > 0">
      <div class="col-sm-12">
        <div class="card-header">
          <h3>ปิดรับสมัคร</h3>
        </div>
        <div class="card">

          <div v-if="loading" class="d-flex justify-content-center">
            <img style="align-items: center; width: 50px" src="../../assets/images/running_loading.svg" />
          </div>
          <div v-else class="my-gallery card-body row gallery-with-description" itemscope="">
            <figure class="col-xl-4 col-sm-6" v-for="(src, index) in this.events_end" :key="index">
              <a v-bind:href="src.event_key">
                <img :src="src.coverurl" alt="Image description" class="img-thumbnail" />
                <div class="caption">
                  <h4>{{ src.title }}</h4>
                  <b><i class="fa fa-calendar-check-o" aria-hidden="true"></i> {{ src.event_date_text }}</b>
                  <p><i class="fa fa-map-marker" aria-hidden="true"></i> {{ src.location }}</p>
                </div>
              </a>
            </figure>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from "axios";
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
export default {
  setup() {
    const siteData = reactive({
      title: `ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH`,
      description: `ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH`,
      keywords: `หารูปวิ่ง,งานวิ่ง,runrace,ระบบรับสมัครงานวิ่ง`,
      image: process.env.VUE_APP_IMAGE_COVER,
    })

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords),
        },
        {
          property: `og:image`,
          content: computed(() => siteData.image),
        },
      ],

    })

  },
  data() {
    return {
      events_open: [],
      events_soon: [],
      events_end: [],
      loading: true,
    };
  },
  
  
  async mounted(){
//console.log("this.loading"+ this.loading)
this.loading = true
    let arr_event = await this.getEvents();

    for (let index = 0; index < arr_event.length; index++) {
      if (arr_event[index].event_status === 'OPEN') {
        this.events_open.push(arr_event[index])
      } else if (arr_event[index].event_status === 'SOON') {
        this.events_soon.push(arr_event[index])
      } else {
        this.events_end.push(arr_event[index])
      }
    }

    this.events_soon.sort((a, b) => a.event_date.localeCompare(b.event_date));
    //console.log("this.loading"+JSON.stringify(this.events))

    this.loading = false;
    //console.log("this.loading"+ this.loading)
  },

  methods: {
    async getEvents() {
      const res = await axios.get("/v1/events");
      return res.data;
    },
  },
};
</script>